import { Carousel4 } from "./Carousel4";
import Services from "./Services";
import About from "./About";
import Fact from "./Fact";
import NewsLetter from "./Newsletter";
import Map from "./Map";
import React, { useEffect, useContext } from "react";

import { AppContext } from "../../App.js";

export default function Home() {
  const { showNewsLetter, setShowNewsLetter } = useContext(AppContext);

  const handleCloseNews = () => {
    setShowNewsLetter(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowNewsLetter(true);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [setShowNewsLetter]);
  return (
    <>
      <NewsLetter show={showNewsLetter} handleClose={handleCloseNews} />
      <Carousel4 />
      <Services />
      <About />
      <Fact />
      <Map />
    </>
  );
}
