import axios from "axios";
import React from "react";

const ProductsAPI = () => {
  axios
    .get(
      "https://www.dtainternacional.com/apidta/productsAPI/get_product.php?cat=lubricante"
    )
    .then(function (response) {
      console.log(response.data);
    })
    .catch(function (error) {
      console.error("Error fetching data:", error);
    });

  return <div>ProductsAPI</div>;
};

export default ProductsAPI;
