import ProductComponent from "./ProductComponent";
import { useParams, Link } from "react-router-dom";
import ProductsAPI from "./ProductsAPI";

function VistaProductos() {
  const { categoria } = useParams();
  const componentsArray = Array.from({ length: 8 }, (_, index) => (
    <ProductComponent key={index} />
  ));

  return (
    <>
      <div>
        <ProductsAPI />
      </div>
      {/* Page Header Start */}
      <div className="container-fluid page-header mb-5 py-5">
        <div className="container">
          <h1
            className="display-3 text-white mb-3 animated slideInDown"
            style={{ textTransform: "capitalize" }}
          >
            {categoria}
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb text-uppercase">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Inicio
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Productos
                </Link>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Lubricantes
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* Team Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="text-secondary text-uppercase">Productos</h6>
            <h1 className="mb-5">Nuestros Productos</h1>
          </div>
          <div className="row g-4">{componentsArray}</div>
        </div>
      </div>
      {/* Team End */}
    </>
  );
}

export default VistaProductos;
