import Footer from './layouts/Footer';
import Header from './layouts/Header';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Solicitud from "./components/Solicitud";
import Home from "./components/home/Home";
import VistaProductos from './components/products/ProductsView';
import NewsletterPanel from './components/home/NewsletterPanel';

import { createContext, useState } from "react";

export const AppContext = createContext();

function App() {
  const [showNewsLetter, setShowNewsLetter] = useState(false);
  return (
    <div className="App">
      <Router>
        <AppContext.Provider value={{ showNewsLetter, setShowNewsLetter }}>


          <Header />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/solicitud' element={<Solicitud />} />
            <Route path='/productos' element={<VistaProductos />} />
            <Route path='/newsletterpanel' element={<NewsletterPanel />} />
            <Route path='*' element={<Home />} />
          </Routes>
          <Footer />
        </AppContext.Provider>
      </Router>

    </div>
  );
}

export default App;
