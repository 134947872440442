function ProductComponent() {
  return (
    <>
      <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
        <div className="team-item">
          <div className="position-relative overflow-hidden">
            <img
              className="img-fluid p-2"
              src="/img/img2/Lubriguard.jpg"
              alt=""
            />
          </div>
          <div className="team-text">
            <h5 style={{ color: "black" }}>
              Cubeta 5 galones Lubricante para motores diesel Universal SAE
              15W-40 CI-4/S
            </h5>
          </div>
          <div className="producto-botonAgregar">
            <button
              className="btn btn-primary"
              type="button"
              style={{ marginRight: 15 + "px" }}
            >
              Agregar al carrito
            </button>
            <button className="btn btn-primary" type="button">
              Descripcion
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductComponent;
