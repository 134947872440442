export default function map() {
  return (
    <>
      {/* Map Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12 wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="text-secondary text-uppercase">Get In Touch</h6>
              <h1 className="mb-4">Direcci&oacute;n</h1>
              <iframe
                title="Mapa"
                className="position-relative w-100"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.0425998783808!2d-69.90800852404551!3d18.481729482604276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8eaf883513ff2275%3A0x8a3de08ec3d89ce7!2sDTA%20Internacional!5e0!3m2!1sen!2sdo!4v1698681160166!5m2!1sen!2sdo"
                style={{ height: 300, border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex={0}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Map End */}
    </>
  );
}
