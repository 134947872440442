import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import "../../App.css";
import React, { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const NewsLetter = ({ show, handleClose }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    // Set imageLoaded to true once the image has finished loading
    setImageLoaded(true);
  };
  const schema = yup.object().shape({
    nombre: yup.string().required().max(50),
    apellido: yup.string().required().max(50),
    correo: yup.string().email().required().max(50),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: <p>Registrando...</p>,
      backdrop: false,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    }).then(() => {});
    console.log(data);
    axios
      .post("https://dtainternacional.com/apidta/newsletter.php", data, {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // Handle the response from the server
        console.log(response.data);
        if (response.data.mensaje === "ok") {
          MySwal.close();
          MySwal.fire({
            icon: "success",
            title: "Usuario registrado en el bolet&iacute;n",
            backdrop: false,
            allowOutsideClick: false,
          }).then(() => {
            reset();
            //setShowNewsLetter(false);
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <div
        className="image-container"
        style={{ display: imageLoaded ? "block" : "none" }}
      >
        <img
          src="img/banner.jpg"
          alt=""
          className="background-image"
          onLoad={handleImageLoad}
        />
        <div className="form-container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row g-3">
              <div className="col-7">
                <div className="form-floating">
                  <input
                    autoComplete="__away"
                    type="text"
                    maxLength={"50"}
                    className={
                      errors.nombre
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    id="nombre"
                    name="nombre"
                    placeholder="nombre"
                    {...register("nombre")}
                  />
                  <label htmlFor="nombre">Nombre</label>
                </div>
              </div>
              <div className="col-7">
                <div className="form-floating">
                  <input
                    autoComplete="__away"
                    type="text"
                    maxLength={"50"}
                    className={
                      errors.apellido
                        ? "form-control border-danger"
                        : "form-control "
                    }
                    id="apellido"
                    name="apellido"
                    placeholder="Contrase&ntilde;a"
                    {...register("apellido")}
                  />
                  <label htmlFor="apellido">Apellido</label>
                </div>
              </div>
              <div className="col-7">
                <div className="form-floating">
                  <input
                    autoComplete="__away"
                    type="email"
                    className={
                      errors.email
                        ? "form-control border-danger"
                        : "form-control "
                    }
                    id="correo"
                    maxLength={"50"}
                    name="correo"
                    placeholder="Correo"
                    {...register("correo")}
                  />
                  <label htmlFor="correo">Correo</label>
                </div>
              </div>
              <div className="col-10">
                <button className="btn btn-primary" type="submit">
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default NewsLetter;
